.main-header .navbar .nav > li.user {
    .fas, .far {
        margin-right: 5px;
    }
}

.sidebar-menu > li {
    .fas, .far {
        width: 20px;
    }
}
