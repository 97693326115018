/* Common styles for 14o projects */

.margin-top-02 {
    margin-top: 2px;
}

.margin-top-05 {
    margin-top: 5px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-bottom-05 {
    margin-bottom: 10px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-right-05 {
    margin-right: 5px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-left-05 {
    margin-left: 5px;
}

.margin-left-10 {
    margin-left: 10px;
}

.nomargin {
    margin: 0px !important;
}

.padding-top-05 {
    padding-top: 5px;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-right-05 {
    padding-right: 5px;
}

.padding-left-05 {
    padding-left: 5px;
}

.nopadding {
    padding: 0px !important;
}

.bold300 {
    font-weight: 300;
}

.bold500 {
    font-weight: 500;
}

.bold600 {
    font-weight: 600;
}

.bold700 {
    font-weight: 700;
}

.required_error {
    border: 1px solid $gold !important;
}
