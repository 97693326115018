.my_soda_table_hidden {
    position: absolute;
    z-index: -1;
    border: 0px !important;
    thead {
        opacity: 0;
        border: 0px !important;
        tr {
            border: 0px !important;
        }
        th {
            border: 0px !important;
        }
    }
    tbody {
        border: 0px !important;
    }
    tr {
        border: 0px !important;
    }
    &.table>thead>tr>th {
        border: 0px !important;
    }
}

.my_soda_table {
    position: relative;
    z-index: 1;
    tbody {
        display: none;
    }
}
