body {
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #eee;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 13px;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    font-family: Helvetica, Arial, sans-serif;
}

.p-a-3 {
    padding: 3px;
}

.p-a-5 {
    padding: 5px;
}

.drag-cursor:hover {
    cursor: pointer;
}

#title_bar {
    .title_col {
        padding-top: 5px;
        padding-bottom: 5px;
    }
    a {
        color: $white;
    }
    .navbar-nav > li > a {
        padding: 2px 0;
    }
    .icon-size {
        font-size: 45px;
    }
}

#title_bar .navbar-nav > li > a:hover,
#title_bar .nav .open > a,
.nav .open > a:focus,
.nav .open > a:hover {
    background-color: $soda-gray-darker;
}

#profile-menu {
    .dropdown-menu {
        background-color: $soda-gray-darker;
        min-width: 225px;
        &a:hover {
            color: $soda-gray-darker;
        }
    }
}

.sign-in-logo {
    display: block;
    width: 300px;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 20px;
}

.login-form-wrapper {
    display: block;
    margin: auto;
    width: 300px;
    max-width: 100%;
}

#editor {
    min-height: 400px;
}

.navbar-actions {
    list-style: none;
    padding: 0px;
}

.navbar-clearfix {
    display: block;
    clear: both;
    float: none;
}

/* Tree table */

.table-tr-clickable tbody tr:hover {
    cursor: pointer;
}

.table > thead > tr.treegrid-expanded:not(.treegrid-ischild) > td,
.table > tbody > tr.treegrid-expanded:not(.treegrid-ischild) > td,
.table > thead > tr.treegrid-collapsed:not(.treegrid-ischild) > td,
.table > tbody > tr.treegrid-collapsed:not(.treegrid-ischild) > td {
    background: #dbf0f7 none repeat scroll 0 0;
    border-color: #b6e2ef;
}

.table > thead > tr.is_parent_Y:not(.treegrid-ischild) > td,
.table > tbody > tr.is_parent_Y:not(.treegrid-ischild) > td,
.table > thead > tr.is_parent_N.parenttr:not(.treegrid-ischild) > td,
.table > tbody > tr.is_parent_N.parenttr:not(.treegrid-ischild) > td {
    background: #dbf0f7 none repeat scroll 0 0;
    border-color: #b6e2ef;
}

.treegrid-expander.glyphicon.glyphicon-minus {
    color: #999999;
}

.table-invisible {
    display: none !important;
}

.menu-container-needs-save {
    border: 2px solid orange !important;
}

.menu-text-needs-save {
    color: orange;
    margin-top: 10px;
    text-align: center;
}

.menu-icon-group input[type='checkbox'] {
    margin-right: 10px;
    margin-bottom: 10px;
}

.menu-icon-wrapper {
    border: 1px solid $lightgray;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
}

.menu-module-state-label {
    font-size: 85%;
    font-weight: normal;
    color: #777;
}

.btn-icon-picker {
    font-weight: normal;
    color: #333;
    background: #dbf0f7;
    border: 1px solid #333;
    border-radius: 10px;
    padding: 5px 10px;
}

.btn-icon-picker:disabled {
    border: 1px solid #cccccc;
    color: #cccccc;
}

.menu-icon {
    width: 36px;
    height: auto;
}

.dd-list.dd-dragel img {
    max-width: 16px;
    max-height: 16px;
}

.rankdragger {
    cursor: move;
}

table {
    tr.sortable-placeholder {
        display: table-row;
        height: 38px;
        background-color: #fff9e5 !important;
    }
    .sortable-placeholder td {
        display: table-cell;
        background-color: #fff9e5 !important;
    }

    .table-row-muted {
        background-color: rgb(251, 251, 251) !important;

        td {
            color: #888;
        }
    }
}

#adminform label.control-label span.label {
    font-size: 8px;
}

#o_columns {
    li span {
        cursor: pointer;
    }
    .o_columns_group {
        background: #ececec !important;
    }
    .sortable-placeholder {
        list-style: none;
        height: 20px;
        width: 100%;
        display: block;
    }
}

/*
#table_companies.table {
    display: none;
}

#table_modules.table {
    display: none;
}

#table_users.table {
    display: none;
}

#dataTables-footer.table {
    display: none;
}
*/

#order_columns_container {
    .dragger {
        cursor: pointer;
    }
}

.list-group-header {
    background: #ececec !important;
}

.list-group-item {
    &.group_1,
    &.group_2 {
        display: none;
    }
}

#no_order_columns .o_columns_group {
    background: #ececec !important;
}

#no_order_columns2 {
    .o_columns_group {
        background: #ececec !important;
    }
    &.list-group {
        margin-bottom: 0px !important;
    }
    .list-group-item:last-child {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        margin-bottom: 0;
    }
}

.list-view-toogle {
    display: none;
}

.modal {
    overflow-y: auto;
}

.modal-title {
    font-size: 18px;
}

.modal-content {
    min-height: 100%;
    height: auto;
    border-radius: 0;
    @include transition(height, 0.5s);
    @include transition(min-height, 0.5s);
}

.push_header_info {
    font-size: 12px;
    text-align: right;
    padding: 8px;
    margin-right: 20px;
}

.push_header_permanent {
    border-bottom: 2px solid #f0f3f5;
    label {
        padding-bottom: 5px;
        color: #333;
    }
    &.closed {
        background-color: #f0f3f5;
        border-bottom: 0px;
    }
}

.push_header {
    border-bottom: 2px solid #f0f3f5;
    cursor: pointer;
    position: relative;
    label {
        padding-bottom: 5px;
        color: #333;
    }
    &:before {
        content: '\f0d7';
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        right: 5px;
        position: absolute;
        top: 5;
        font-size: 26px;
        color: #999;
    }
    &.closed:before {
        content: '\f0da';
        font-family: 'Font Awesome 5 Free'; /* updated font-family */
        font-weight: 900;
    }
}

.push_subsection {
    display: block;
    &.closed {
        display: none;
    }
}

.soda-spinner {
    color: #999;
    margin: 10px;
    font-size: 18px;
}

.dataTables-spinner {
    @extend .soda-spinner;
    margin: 0;
}

/* 2.3 Questions Alert update */

.update_warnings_msg {
    position: absolute;
    top: 20px;
    left: 30px;
    .fa-exclamation-triangle {
        color: #ffae00;
    }
}

.update_warning {
    position: relative;
    .fa-exclamation-triangle {
        color: #ffae00;
        position: absolute;
        right: -8px;
        top: -2px;
    }
}

.warning {
    .fa-exclamation-triangle {
        color: #ffae00;
    }
}

.titlebar {
    background: #eeeeee;
    border: 1px solid #eeeeee;
    clear: both;
    display: block;
    float: none;
    height: 41px;
    margin-top: 20px;
    -webkit-border-radius: 0px;
    > h1 {
        font-size: 18px;
        margin: 10px;
    }
    > h3 {
        font-size: 15px !important;
        margin: 10px;
    }
    .navbar-actions {
        margin: 5px 10px;
    }
}

.history .panel {
    border-radius: 0px;
}

#submit-search-btn {
    display: none;
}

#cancel-search-btn {
    display: none !important;
}

#search-form-input {
    background: url('../../images/search.png') top right no-repeat;
    background-size: contain;
    border-radius: 0;
    font-size: 12px;
    height: 30px;
    line-height: 1.5;
    padding: 5px 10px;
    font-size: 12px;
}

.m-b-10 .pull-left {
    display: none;
}

#per-page-dropdown {
    font-size: 12px;
    height: 30px;
    line-height: 12px;
    padding: 0px;
}

.dd {
    width: 88%;
}

.box {
    border-radius: 0px;
    border: 0px;
    box-shadow: none;
}

.box-body {
    @extend .box;
}

.borderaround {
    border-left: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
    .pagination {
        margin: 20px !important;
    }
}

.box.box4table {
    @extend .borderaround;
}

.btn-auth {
    color: $white;
    background-color: $soda-primary;
    border-color: $soda-primary;
}

.open .dropdown-toggle.btn-auth,
.btn-auth {
    &:hover,
    &:focus,
    &:active,
    &.active {
        color: $white;
        background-color: $soda-blue-darker;
        border-color: $soda-primary;
    }
}

.open .dropdown-toggle.btn-auth,
.btn-auth {
    &:active,
    &.active {
        background-image: none;
    }
}

.btn-auth.disabled,
.btn-auth[disabled],
fieldset[disabled] .btn-auth,
.btn-auth.disabled:hover,
.btn-auth[disabled]:hover,
fieldset[disabled] .btn-auth:hover,
.btn-auth.disabled:focus,
.btn-auth[disabled]:focus,
fieldset[disabled] .btn-auth:focus,
.btn-auth.disabled:active,
.btn-auth[disabled]:active,
fieldset[disabled] .btn-auth:active,
.btn-auth.disabled.active,
.btn-auth[disabled].active,
fieldset[disabled] .btn-auth.active {
    background-color: $soda-primary;
    border-color: $soda-primary;
}

.btn-auth .badge {
    color: $soda-primary;
    background-color: $white;
}

.login-form-wrapper h1 {
    font-size: 20px;
}

.skin-blue {
    .main-header {
        .navbar,
        .logo,
        li.user-header {
            background: $soda-primary !important;
        }
    }
}

.bg-primary {
    background-color: $soda-primary !important;
}

.logo-lg {
    background-attachment: scroll;
    background-clip: border-box;
    background-color: rgba(0, 0, 0, 0);
    background-image: url('../../images/Info_Grove_Logo_Dark.png');
    background-origin: padding-box;
    background-position: left top;
    background-repeat: no-repeat;
    background-size: 60% auto;
    display: block;
    height: 40px;
    line-height: 40px;
    margin: 8px 0;
    text-align: left;
    text-indent: -99999px;
    width: 230px;
}

/* Navigation Updates SODA 2.4 */
.skin-blue {
    .sidebar-menu {
        > li:hover > a,
        > li.active > a {
            border-left-color: #00b3e2 !important;
        }
    }
}

/*
.skin-blue .wrapper,
.skin-blue .main-sidebar,
.skin-blue .left-side {
    background: #fff !important;
    border-right: 3px solid #dddddd;
    border-left: 3px solid #dddddd;
}

.skin-blue .sidebar-menu>li.header {
    background: #dddddd !important;
    color: #000 !important;
    font-size: 12px;
    font-weight: 600;
}

.skin-blue .sidebar-menu>li.header .fas {
    margin-right: 4px;
}

.skin-blue .sidebar a {
    color: #000 !important;
}

.skin-blue .sidebar-menu>li:hover>a,
.skin-blue .sidebar-menu>li.active>a {
    background: #eeeeee !important;
    border-left-color: #00b3e2 !important;
}
*/

.form_spinner {
    display: none;
}

.bg-aqua,
.callout.callout-info,
.alert-info,
.label-info,
.modal-info .modal-body {
    background: $aqua !important;
}

.modal.in {
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.modal-backdrop.in {
    opacity: 0.3;
}

.bg-red,
.callout.callout-danger,
.alert-danger,
.alert-error,
.label-danger,
.modal-danger .modal-body {
    background: #ee2938 !important;
}

.settings_dropdown {
    border: 1px solid #ccc;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.checklist_dropdown {
    @extend .settings_dropdown;
    padding: 15px;

    .dropdown-body {
        max-height: 300px;
        overflow-y: scroll;
    }
}

.list_item_dropdown {
    @extend .settings_dropdown;
    padding: 15px 0 15px 0;

    > li > a {
        cursor: pointer;
        padding-bottom: 5px;
        padding-top: 5px;
    }
}

.thumbnail {
    display: inline-block;
    position: relative;
    width: 75px;
    height: 75px;
    overflow: hidden;
}

.thumbnail img {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100%;
    width: auto;
    transform: translate(-50%, -50%);
}

.thumbnail img.portrait {
    width: 100%;
    height: auto;
}

.alert {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: left;
}

.close_message {
    flex: 0 0 20px;
    display: inline-block;
    text-align: right;
    cursor: pointer;
}

.cc-message {
    font-size: 13px;
    line-height: 20px;
    margin-top: 5px;
}

.field-info-tooltip-container .tooltip-inner,
.in-use-tooltip-container .tooltip-inner {
    text-align: left;
    white-space: pre-wrap;
}

.field-info-icon {
    color: #3c8dbc;
    cursor: pointer;
    font-size: 13px;
    margin-left: 5px;
}

[data-toggle='tooltip'] {
    cursor: pointer;
}

.table-data-source {
    &.table > thead > tr > th,
    &.table > tbody > tr > th,
    &.table > tfoot > tr > th,
    &.table > thead > tr > td,
    &.table > tbody > tr > td,
    &.table > tfoot > tr > td {
        padding: 9px 5px !important;
    }
}

.help_document {
    height: 500px;
    max-height: 500px;
    min-height: 150px;
    max-width: 700px;
    min-width: 350px;
    overflow: hidden;
    padding-bottom: 15px;
    width: 400px;

    .popover-title {
        height: 52px;
    }

    .popover-content {
        height: calc(100% - 52px);
        overflow: auto;
        padding: 14px;
        width: 100%;
    }

    &__content p {
        margin-bottom: 10px;
    }

    &__content .sub_content {
        background: #eeeeee;
        border: 1px solid #cccccc;
        padding: 5px 10px;
        margin-left: 20px;
        margin-bottom: 10px;
    }

    &__content img {
        max-width: 100%;
    }

    &__content table {
        width: 100%;
        margin-bottom: 10px;
    }

    &__content th,
    &__content td {
        padding: 4px 8px;
    }

    &__editor {
        padding: 14px;
        background-color: #fff;
    }
}

.help_documents_list {
    .help_documents_list_item {
        font-size: 16px;
        list-style-type: none;
        margin-left: 50px;
    }
}

.grabbable {
    cursor: move; /* Fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

.ellipsis {
    text-overflow: ellipsis;
}

.colorpicker.dropdown-menu {
    z-index: 10000;
}

.colorpicker.input-group {
    z-index: auto;
    padding: 0;
    margin: 0;
}

.colorpicker-element .clear-button {
    position: absolute;
    font-size: 1.4em;
    top: 0.4em;
    color: gray;
    z-index: 10;
    float: right;
    width: 2em;
    right: 0px;
    text-align: center;
    cursor: pointer;
}

.colorpicker-element .input-group-addon i {
    border: black;
    border-style: solid;
    border-width: 1px;
}

/* Ensure that the dragged item fills the width of the table */
.ui-sortable-helper {
    display: table;
}

span.drag_span:empty {
    display: block;
    min-height: 10px;
    background-color: #f7f7f7;
}

#modal-body-tab {
    border: 1px solid darkgrey;
    padding: 10px 10px;
}

#modal-header-tabs {
    a.modal-tab-active {
        color: $soda-blue-darker;
        font-weight: 500;
    }

    a {
        background: white !important;
        color: black;
        border-width: 1px 1px 0 1px;
        border-style: solid;
        border-color: darkgrey !important;
        border-radius: 0;
    }
}

.modal-header-select {
    color: #ffffff;
    background-color: #005da6;
    border-radius: 3px;
    border-color: #005da6;
    font-size: 12px;
    font-weight: 300;
    font-family: Helvetica, Arial, sans-serif;
    margin-left: 10px;
    height: 30px;
}

.modal-tab-header {
    background-color: #ddd;
    width: 100%;
    margin-bottom: 16px;
    padding: 4px 8px 4px 8px;
    font-size: 18px;
    font-weight: bold;
    min-height: 42px;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.dashboard-item-readonly {
    display: block;
    min-height: 30px;
    margin: 5px 0;
    padding: 5px 10px;
    color: #333;
    text-decoration: none;
    font-weight: 700;
    border: 1px solid #ccc;
    background: #fafafa;
    border-radius: 3px;
    box-sizing: border-box;
}

.titlebar-badge-red {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 50px;

    div {
        font-size: 12px;
        color: white;
        background-color: red;
        padding: 0 6px 0 6px;
    }
}

/*Spinner centered*/
.fa-spinner {
    width: 100%;
    margin: auto;
}
.errorMsge {
    color: red;
    margin-top: 10px;
    text-align: center;
}

/* Cover Layer to show while navigating to a direct link */

.dl-cover-layer {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color:rgb(90, 90, 90);
    z-index: 9999;
    display: none;
    align-items: center;
}

.dl-highlighted {
    background-color: #fffed0 !important;
}

.dl-progress-bar {
    background-color: #5555FF;
    margin: 0px !important;
}

/* Module Validation Messages */
.validation-messages-container {
    font-size: 0.83em;
}

.validation-message-container {
    margin-top: 2px;
}

.btn-sleep-validation-message {
    display:inline-block;
    padding:1px 2px;
    padding-bottom:0px;
}

.btn-sleep-validation-message:hover{
    background-color: rgba(0, 0, 0, 0.1);
}

.btn-reactivate-validation-message {
    border-color: rgba(255, 255, 255, 0);
}

.btn-reactivate-validation-message:hover {
    background-color: rgb(225, 225, 225);
}

.validation-popover-content {
    font-size: .8em;

    div {
        margin-top: 8px;
    }

    .validation-popover-help {
        color: rgb(95, 95, 95);
    }
}

/* Debug Bar */

// Hide the toolbar on the SQL queries. On Prod especially (which debug bar is only enabled for admins)
// this list of databases (on the backend view) is so large you cannot see anything
div.phpdebugbar-widgets-sqlqueries div.phpdebugbar-widgets-toolbar  {
    display: none !important;
}
