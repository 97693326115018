$soda-form-horizontal-margin: 10px;
$soda-form-vertical-margin: 10px;

.form-header {
    position: relative;
}

form.soda-form {
    background: $white;
    padding: 15px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    overflow: hidden;
    //@extend .form-horizontal;

    label {
        @extend .col-sm-3, .text-right;
        padding-top: 0;
        padding-left: 0; // for mobile view
        margin-bottom: 0;
        font-size: 14px;
        font-weight: 500;
        @media (min-width: 768px) {
            padding-top: 7px;
            padding-left: 15px;
        }
    }

    select {
        @extend .form-control, .col-sm-8;
        margin-bottom: 15px;
        @media (min-width: 768px) {
            width: 66.6666666667%;
        }
    }

    input[type='checkbox'] {
        box-shadow: none !important;
        margin: 0;
    }

    input:not([type='button']):not([type='submit']),
    textarea {
        @extend .form-control, .col-sm-8;
        margin-bottom: 15px;
        @media (min-width: 768px) {
            margin-bottom: 33px;
        }
        @media (min-width: 992px) {
            margin-bottom: 15px;
        }
    }

    input[type='button'],
    input[type='submit'] {
        // style buttons here
        @extend .btn, .btn-primary, .form-control, .col-sm-4, .col-sm-offset-8;
        @media (min-width: 768px) {
            min-width: 200px;
            width: auto;
        }
    }

    .form-group {
        @extend .row;
        margin: 0;
    }
}

form.soda-form-readonly {
    @extend form, .soda-form;

    input:not([type='button']):not([type='submit']),
    select,
    textarea {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        color: #8c8c8c;
        pointer-events: none;
    }

    input[type='checkbox'] {
        opacity: 0.5;
    }

    .soda-form-enable-edit {
        -webkit-user-select: auto;
        -moz-user-select: auto;
        -ms-user-select: auto;
        -o-user-select: auto;
        user-select: auto;
        color: #555555 !important;
        pointer-events: auto !important;
    }
}

div.soda-form {
    @extend form, .soda-form;
    padding: 0;
}

div.soda-form-readonly {
    @extend form, .soda-form;

    input:not([type='button']):not([type='submit']),
    select,
    textarea {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        -o-user-select: none;
        user-select: none;
        color: #8c8c8c;
        pointer-events: none;
    }

    .soda-form-enable-edit {
        input:not([type='button']):not([type='submit']),
        select,
        textarea {
            -webkit-user-select: auto;
            -moz-user-select: auto;
            -ms-user-select: auto;
            -o-user-select: auto;
            user-select: auto;
            color: #555555 !important;
            pointer-events: auto !important;
        }
    }
}

.tab-content-form {
    padding: 0 20px 20px 20px;
    border: 1px solid #ddd;
}

.soda-form-heading-container,
.soda-form-label-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-end;
}

.form-collapsable {
    margin-left: 10px;
    font-size: 18px;
    vertical-align: middle;
}

.soda-form-annotation {
    font-size: 11px;
    line-height: 1.2;
    padding-top: 1px;
}

.soda-form-annotation-align-right {
    display: block;
    float: right;
    padding-top: 3px;
    font-size: 11px;
}

.soda-form-group-subfields {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.soda-form-fieldset {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: -$soda-form-horizontal-margin;
    margin-right: -$soda-form-horizontal-margin;
}

.soda-form-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;

    margin-left: $soda-form-horizontal-margin;
    margin-right: $soda-form-horizontal-margin;
    padding: 0 $soda-form-horizontal-margin $soda-form-vertical-margin $soda-form-horizontal-margin;
    border: 1px solid #ddd;
}

#widget_iframe_container,
.soda-tab-container,
.soda-form-group-large {
    flex: 3 3 100%;
    margin-left: $soda-form-horizontal-margin;
    margin-right: $soda-form-horizontal-margin;
}

.soda-form-group-medium {
    flex: 2 2 45%;
    min-width: 300px;
    margin-left: $soda-form-horizontal-margin;
    margin-right: $soda-form-horizontal-margin;
}

.soda-form-group-small {
    flex: 1 1 21%;
    min-width: 150px;
    margin-left: $soda-form-horizontal-margin;
    margin-right: $soda-form-horizontal-margin;
}

.soda-tab-pane {
    margin-left: -$soda-form-horizontal-margin;
    margin-right: -$soda-form-horizontal-margin;
}

.soda-form-heading-container {
    margin-left: $soda-form-horizontal-margin;
    margin-right: $soda-form-horizontal-margin;
    margin-top: 0;
    margin-bottom: 10px;
}

.soda-form-heading-container-empty {
    margin-top: $soda-form-vertical-margin;
}

.soda-array-group-heading {
    margin-top: $soda-form-vertical-margin * 2;
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 700;
}

.form_array_item_empty {
    display: block;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 10px;

    font-style: italic;
    text-align: center;
}

.form_array_item_container {
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 10px;

    padding: $soda-form-horizontal-margin 0;
    border: 1px #111111 solid;
    border-collapse: collapse;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;
}

.form_array_item_container_fields {
    flex: 1 1 auto;
}

.form_array_item_container_actions {
    flex: 0 0 50px;
    margin: auto;
}

.form_array_item_container_dragger {
    flex: 0 0 20px;
    margin: auto;
    margin-left: 15px;
}

.form_array_add {
    padding-left: 10px;
}

input[type='checkbox'][disabled] {
    background: #111111;
}

.checkbox-horizontal {
    display: inline-block;
    margin-right: 8px;
}

.form-photo-thumbnail {
    display: block;
    padding: 6px 0;

    img {
        min-height: 60px;
        height: 60px;
        min-width: 50px;
        max-width: 100%;
        background-color: #eeeeee;
    }
}

.form-photo-display {
    display: block;
}

.form-html-display {
    font-size: 14px;
}

.file-input-optional::after {
    content: '  [Optional]';
    color: rgb(157, 157, 157);
}

input[type='file'] {
    border: 0;
}

input[type='file'].form-photo-input {
    padding: 6px 0;
}

input[type='checkbox'].form-checkbox-right {
    margin: 0 5px 0 0;
    vertical-align: middle;
}

input[type='checkbox'].form-checkbox-left {
    margin: 0 0 0 5px;
    vertical-align: middle;
}
