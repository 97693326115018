.content-wrapper,
.right-side {
    background: #fefefe;
}

.wrapper.adminlte_wrapper {
    height: auto;
    padding: 0px !important;
    position: static !important;
}

.main-header .title {
    font-size: 18px;
    color: $white;
    line-height: 50px;
    display: block;
    float: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@media only screen and (min-width: 400px) {
    .main-header .title {
        width: 200px;
    }
}

@media only screen and (min-width: 768px) {
    .main-header .title {
        width: 350px;
    }
}

.navbar-nav>.user-menu>.dropdown-menu>li.user-header {
    height: auto;
}

.main-header .sidebar-toggle:before {
    font-family: "Font Awesome 5 Free" !important;
    font-weight: 900 !important;
}
