// mixins
@mixin transition($prop, $time) {
    -moz-transition: $prop $time;
    -webkit-transition: $prop $time;
    -o-transition: $prop $time;
    transition: $prop $time;
}

@mixin dd-handle-icon($content, $color: $soda-gray, $font-size: 14px) {
    content: $content;
    font-family: "Font Awesome 5 Free";
    font-style: normal;
    font-weight: 900;
    text-decoration: inherit;
    /* --adjust as necessary-- */
    color: $color;
    font-size: $font-size;
    position: absolute;
    top: 5px;
    right: 3px;    
}