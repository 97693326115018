.forums-post-container {
    padding:5px;
    margin:5px;
    margin-top:0px;
}

.forums-report-post-body {
    margin-top:5px;
    margin-bottom:15px;
}

.forums-table-row-orange {
    background-color: #FF990010 !important;
}

.forums-table-row-red {
    background-color: #ff000010 !important;
}

.forums-table-row-border-top {
    border-top: 2px solid rgb(63, 63, 63) !important;
}

.forums-table-checkbox {
    width: 17px;
    height: 17px;
    margin-top:1px !important;
}