#target_individuals_form_group {
    select[multiple] {
        height: 200px;
    }
}

#target_user_groups_form_group {
    select[multiple] {
        height: 200px;
    }
}