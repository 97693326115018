// colors
$white: #ffffff;
$black: #000000;

$gold: gold;
$lightgray: #ddd;
$aqua: #00b4e5;

$soda-primary: #005DA6;
$soda-red: #EE2C37;
$soda-red-darker: #DB0F1A;

$soda-blue: #348fe2;
$soda-blue-darker: #006BBD;

$soda-gray: #979898;
$soda-gray-darker: #777;
