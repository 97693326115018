.button-delete {
    position: absolute;
    top: 4px;
    right: -54px;
}

.button-edit {
    position: absolute;
    top: 4px;
    right: -28px;
}

.button-enable {
    position: absolute;
    top: 4px;
    right: -54px;
}

.button-disable {
    position: absolute;
    top: 4px;
    right: -54px;
}

.button-editdis {
    position: absolute;
    top: 4px;
    right: -28px;
}

.button-deletedis {
    position: absolute;
    top: 4px;
    right: -54px;
}

#menu-editor {
    margin-top: 20px;
}

#saveButton {
    padding-right: 30px;
    padding-left: 30px;
}

.output-container {
    margin-top: 20px;
}

#json-output {
    margin-top: 20px;
}

#nestable {
    .dd-item {
        &[data-isparent='1']>.dd-handle {
            background: #dbf0f7;
        }
        &[data-disabled='1']>.dd-handle {
            background: #eee;
            cursor: no-drop;
            color: $lightgray;
        }
        >button[data-action="collapse"]::before {
            content: " ";
        }
        &.dd-nodrag[data-propagation="optional"]>.dd-handle::after {
            content: "\f023";
        }
        >button::before {
            content: " ";
        }
        >button[data-action="collapse"]::before {
            content: " ";
        }
        &.dd-nodrag[data-propagation="optional"]>.dd-handle::after {
            @include dd-handle-icon("\f09c");
        }
        &.dd-nodrag[data-propagation="musthave"]>.dd-handle::after {
            @include dd-handle-icon("\f023");
        }
        >.dd-handle {
            cursor: move !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: break-spaces;
            min-height: 30px;
            height: unset;
        }
        &.dd-nodrag>.dd-handle {
            cursor: auto !important;
        }
        >.dd-handle::before {
            @include dd-handle-icon("\f0b2");
        }
        &.dd-nodrag>.dd-handle::before {
            @include dd-handle-icon(" ");
        }
        .menu-icon-in {
            width: auto;
            height: 16px;
        }
    }
    .dd-collapse {
        display: none !important;
    }
    .dd-expand {
        display: none !important;
    }
}

.boxpaginated {
    .panel-heading {
        display: none;
    }
    .jumbotron.bg-white {
        margin: 0px;
        padding: 0px;
        h3 {
            font-size: 1em;
            color: $black !important;
            text-align: left !important;
        }
    }
    #submit-search-btn {
        background-color: $soda-blue !important;
        border-color: $soda-blue !important;
    }
    .pagination {
        >.active {
            >a,
            >a:hover,
            >a:focus,
            >span,
            >span:hover,
            >span:focus {
                background-color: $soda-blue !important;
                border-color: $soda-blue !important;
            }            
        }
    }
}

.subentities-list {
    margin: 5px 0px 5px 10px;
    padding: 0px;
    list-style: none;
    ul {
        margin: 5px 0px 5px 20px;
        padding: 0px;
        list-style: none;
    }
}

.topics-list {
    margin: 5px 0px 5px 10px;
    padding: 0px;
    list-style: none;
    ul {
        margin: 5px 0px 5px 20px;
        padding: 0px;
        list-style: none;
    }
}

.content-form-wrapper {
    height: 350px;
    overflow-y: scroll;
}
