.animated,
.show {
    visibility: visible !important;
}

.nopadding {
    padding: 0px !important;
}

.notoppadding {
    padding-top: 0px !important;
}

.norightpadding {
    padding-right: 0px !important;
}

.noleftpadding {
    padding-left: 0px !important;
}

.color-green {
    color: #00a65a;
}

.color-red {
    color: red;
}

.color-orange {
    color: #fd8309;
}

.color-grey {
    color: grey;
}

.inherit-font-size {
    font-size: inherit;
}

/*
    When used in an <a> tag, this behaves the same as bootstrap classes like text-danger or
    text-warning: link text lightens slightly on hover.
*/
.link-orange {
    color: #fda809;
}
.link-orange:hover,
.link-orange:focus,
.link-orange:active,
.link-orange:visited {
    color: rgb(255, 187, 61);
}
