.box4table {
    display: block;
    clear: both;
}

/* remove border from tables */
.box4table.box {
    border-radius: 0px;
    border: 0px;
    box-shadow: none;
}

.dataTables_wrapper {
    display: block;
    clear: both;
    padding: 10px;
    background: $white;
}

/* Add icon to the right */
.dataTables_filter {
    text-align: right;
    .input-sm {
        min-width: 180px;
        background: url('../../images/search.png') top right no-repeat;
        background-size: contain;
        border-radius: 0;
    }
    /* Make the search standout more when there is something input */
    input:not(:placeholder-shown) {
        border-width: medium;
        border-color: $soda-primary;
    }
}

.dataTables_paginate {
    @extend .pull-right;
}

table.dataTable thead {
    .sorting,
    .sorting_asc,
    .sorting_desc,
    .sorting_asc_disabled,
    .sorting_desc_disabled {
        position: inherit !important;
        background-repeat: no-repeat;
        background-position: center right;

        &:before {
            content: "" !important;
        }

        &:after {
            content: "" !important;
        }
    }

    .sorting {
        background-image: url("/images/sort_both.png");
    }

    .sorting_asc {
        background-image: url("/images/sort_asc.png");
    }

    .sorting_desc {
        background-image: url("/images/sort_desc.png");
    }

    .sorting_asc_disabled {
        background-image: url("/images/sort_asc_disabled.png");
    }

    .sorting_desc_disabled {
        background-image: url("/images/sort_desc_disabled.png");
    }
}

.dataTables_wrapper .row > [class*="col-"] {
    padding: 0;
}

.treegrid-expander {
    vertical-align: top;
}

.treegrid-indent {
}

.dgt__header {
    .dataTables_filter {
        display: inline-block;
    }

    .dgt__header--left {
        float: left !important;
    }

    .dgt__header--right {
        float: right !important;
    }

    .dgt__column_settings {
        display: inline-block;
        margin-left: 5px;
    }
}

.navbar-select-container {
    margin: 5px 10px;

    select {
        color: $white;
        background-color: $soda-primary;
        border-radius: 3px;
        border-color: $soda-primary;
        font-size: 12px;
        font-weight: 300;
        font-family: Helvetica, Arial, sans-serif;
        height: 30px;
    }
}

.dgt_nowrap {
    white-space: nowrap;
}
