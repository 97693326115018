// Bootstrap Variables
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";

// Chartist Variables
$ct-series-colors: (
    rgba(255,0,1,0.9),
    rgba(55,126,184,0.9),
    rgba(102,166,30,0.9),
    rgba(152,78,163,0.9),
    rgba(0,210,213,0.9),
    rgba(255,127,0,0.9),
    rgba(175,141,0,0.9),
    rgba(127,128,205,0.9),
    rgba(179,233,0,0.9),
    rgba(196,46,96,0.9),
    rgba(166,86,40,0.9),
    rgba(247,129,191,0.9),
    rgba(141,211,199,0.9),
    rgba(190,186,218,0.9),
    rgba(251,128,114,0.9)
    rgba(139,69,19,0.9)
    rgba(160,82,45,0.9)
    rgba(210,105,30,0.9)
    rgba(205,133,63,0.9)
    rgba(244,164,96,0.9)
    rgba(222,184,135,0.9)
    rgba(210,180,140,0.9)
    rgba(188,143,143,0.9)
    rgba(255,228,225,0.9)
    rgba(255,240,245,0.9)
    rgba(245,255,250,0.9)
    rgba(176,196,222,0.9)
);

$ct-text-color: rgba(0,0,0,1);
$ct-text-size: 1em;