#payment_status_container {
    background-color: #ffffff;
    max-width: 480px;
    margin: 0 auto;
    padding: 20px;

    #status_icon {
        color: #ffffff;
        font-size: 50px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .circle {
        border-radius: 50%;
        height: 75px;
        margin: 0 auto;
        position: relative;
        width: 75px;
    }
}

#payments_container {
    background-color: #ffffff;
    max-width: 480px;
    margin: 0 auto;
    padding: 20px;

    .form-group {
        margin-bottom: 0;
    }

    .row {
        margin-bottom: 15px;
        margin-top: 0px;
    }

    #purchase_btn, #submit_btn {
        width: 100%;
    }

    #submit_btn {
        height: 50px;
        margin-top: 30px;
    }

    #amount_text {
        font-size: 16px;
    }

    #payment_info_title {
        color: #bbb;
        letter-spacing: 1px;
        margin: 20px 0;
        text-transform: uppercase;
    }

    #buttons-group {
        margin-bottom: 0;
    }
}
