// Use '~' to identify node_modules for Webpack

// Variables
@import "general/variables";

// Base Bootstrap and jQuery
@import '~bootstrap-sass/assets/stylesheets/bootstrap';
@import '~jquery-ui-dist/jquery-ui.min.css';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

// External components
@import '~jquery-treegrid/css/jquery.treegrid.css';
@import '~nestable2/dist/jquery.nestable.min.css';
@import '~datatables.net-bs/css/dataTables.bootstrap';
@import '~bootstrap-colorpicker/dist/css/bootstrap-colorpicker.min.css';
@import '~@eonasdan/tempus-dominus/dist/css/tempus-dominus.min.css';
@import '~cookieconsent/build/cookieconsent.min.css';

// Chartist
// Custom chartist variables can be found in general/variables
@import '~chartist/dist/scss/chartist.scss';
@import '~chartist-plugin-tooltips-updated/dist/chartist-plugin-tooltip.scss';

// Custom CSS
@import 'lib/adminlte/all';
@import 'general/all';
@import 'partials/all';

@import '../../app/Services/Vendor/witty/laravel-table-view/assets/css/themes/tableview-a.css';
