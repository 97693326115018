.bootstrap-datetimepicker-widget {
    width: 350px !important;
    max-width: 100%;
    table {
        max-width: 100%;
        td.day {
            width: 10% !important;
            padding: 4px !important;
        }
    }
}
