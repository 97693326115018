
.ct-legend {
    list-style: none;
    margin: 20px auto;
    display: inline-flex;
    flex-wrap: wrap;

    li {
        position: relative;
        padding-left: 20px;
        font-size: 14px;
        margin-right: 20px;
    }

    li:before {
        width: 12px;
        height: 12px;
        position: absolute;
        left: 0;
        top: 4px;
        content: '';
        border: 3px solid transparent;
        border-radius: 2px;
    }

    li.inactive:before {
        background: transparent;
    }

    &.ct-legend-inside {
        position: absolute;
        top: 0;
        right: 0;
    }

    @for $i from 0 to length($ct-series-colors) {
        .ct-series-#{$i}:before {
            background-color: nth($ct-series-colors, $i + 1);
            border-color: nth($ct-series-colors, $i + 1);
        }
    }
}

.ct-label.ct-horizontal.ct-end {
    white-space: nowrap;
    writing-mode: vertical-lr; 
    justify-content: flex-start !important;
  }

  .ct-chart-bar .ct-label.ct-horizontal.ct-end {
    align-items: center !important;
  }
 